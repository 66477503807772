import { createI18n } from "vue-i18n";

const messages = {
  cn: {
    events: '活动管理',
    card_records: '明信片排名管理',
    box_records: '抽赏记录管理',
    gift_records: '赠送记录管理',
    water_items: '流水排名管理',
    loser_items: '非酋排名管理',
    banners: "轮播图管理",
    product_brands: "品牌管理",
    ips: "IP管理",
    compounds: "合成管理",
    products: "商品管理",
    product_edit: '商品编辑',
    box_categories: "目录管理",
    box_infos: "套系管理",
    members: "用户管理",
    admins: "管理员管理",
    distributions: "分销管理",
    payment_records: '微信支付管理',
    add_balance_records: '探玩币管理',
    add_point_records: '探玩石管理',
    add_extra_point_records: '军粮管理',
    remove_balance_records: '提现管理',

    pending_events: "活动审核",
    dashboard: "数据统计",
    business_districts: "商圈列表",
    coupon_items: "券种配置",
    coupon_deliver_events: "放券与增发",
    coupon_rule: "领用规则",
    budgets: "预算设定",
    coupon_deliver_rules: "领用规则",
    shops: "夥伴列表",
    coupon_management: "消费券管理",
    event_management: "活动管理",
    coupon_basic: "基础配置",
    coupon_pre_deliver_setting: "预发配置",
    coupon_request_rule: "领取规则",
    coupon_increase_records: "增发日志",
    announcements: "公告管理",
    orders: "订单管理",
    system_settings: "系统管理",
    system_settings_admins: "管理员管理",
    system_settings_shop_banners: "商户端轮播图管理",
    system_settings_user_banners: "用户端轮播图管理",
    news_items: "新闻管理",
    system_settings_users: "用户管理",
    system_settings_roles: "角色管理",
    system_settings_opration_record: "系统操作日志",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
    shipping_management: "发货管理",
    shipping_orders: "发货单管理",
    shipping_products: "发货商品管理",
    shipping_records: "发货记录",
    all_status: "所有状态",
    pending: "待处理",
    processing: "处理中", 
    completed: "已完成",
    all_purchased: "全部采购状态",
    purchased: "已采购",
    not_purchased: "未采购",
    product_name: "商品名称",
    shipping_order: "发货订单",
    shipping_status: "发货状态",
    purchase_price: "采购价格",
    shipping_no: "快递单号",
    deliver: "已发货",
    search_product: "搜索商品名称",
    search_user_mobile: "搜索用户手机号",
    search: "搜索",
    please_input_shipping_provider: "请输入快递供应商",
    please_input_shipping_no: "请输入快递单号",
    batch_deliver: "批量发货",
    confirm: "确认",
    cancel: "取消",
    delivered: "已发货",
    shipping_provider: "快递供应商",
    deliver_success: "发货成功",
    deliver_fail: "发货失败",
    shipping_provider_required: "请输入快递供应商",
    shipping_no_required: "请输入快递单号",
    different_address_error: "所选商品必须属于同一个收货地址",
    delivered_error: "选中商品中包含已发货商品",
    unpurchased_error: "选中商品中包含未采购或采购价为空的商品",
    pocket_items: "用户背包",
    available: "可用",
    used: "已使用",
    user_nickname: "用户昵称",
    user_mobile: "用户手机号",
    product_recycle_price: "商品回收价",
    status: "状态",
    created_at: "创建时间",
    total: "总计",
    items_per_page: "每页条数",
    goto: "前往",
    page: "页",
    waiting_for_deliver: "待发货",
    recycle: "已回收",
    opened: "已开启",
    abandoned: "已废弃",
    compounded: "已合成",
    user_management: "用户管理",
    count: "数量",
    shipping_record_columns: {
      id: 'ID',
      user_nickname: '用户昵称',
      user_mobile: '用户手机号',
      name: '收件人',
      mobile: '手机号',
      area: '地区',
      address_detail: '详细地址',
      shipping_provider: '快递公司',
      shipping_no: '快递单号',
      created_at: '创建时间',
      created_by: '创建人',
      products: '商品信息',
      product_name: '商品名称',
      purchase_price: '采购价格',
      quantity: '数量'
    },
    update_success: "更新成功",
    update_fail: "更新失败",
    confirm_cancel: '确认取消该发货商品?',
    cancel_success: '取消成功',
    cancel_fail: '取消失败',
    cancelled: "已取消",
  },
  en: {
    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
    shipping_management: "Shipping Management",
    shipping_orders: "Shipping Orders",
    shipping_products: "Shipping Products",
    shipping_records: "Shipping Records",
    pending: "Pending",
    deliver: "Delivered",
    search_product: "Search Product Name",
    search_user_mobile: "Search User Mobile",
    search: "Search",
    please_input_shipping_provider: "Please input shipping provider",
    please_input_shipping_no: "Please input shipping number",
    batch_deliver: "Batch Deliver",
    confirm: "Confirm",
    cancel: "Cancel",
    delivered: "Delivered",
    shipping_provider: "Shipping Provider",
    shipping_no: "Shipping No.",
    deliver_success: "Deliver Success",
    deliver_fail: "Deliver Failed",
    shipping_provider_required: "Shipping provider is required",
    shipping_no_required: "Shipping number is required",
    different_address_error: "Selected items must have the same shipping address",
    delivered_error: "Selected items contain delivered items",
    unpurchased_error: "Selected items contain unpurchased or empty price items",
    pocket_items: "User Pocket",
    available: "Available",
    used: "Used",
    user_nickname: "User Nickname",
    user_mobile: "User Mobile",
    product_name: "Product Name",
    product_recycle_price: "Product Recycle Price",
    status: "Status",
    created_at: "Created At",
    total: "Total",
    items_per_page: "Items per page",
    goto: "Goto",
    page: "Page",
    waiting_for_deliver: "Waiting for deliver",
    recycle: "Recycled",
    opened: "Opened",
    abandoned: "Abandoned",
    compounded: "Compounded",
    user_management: "User Management",
    members: "User List",
    all_status: "All Status",
    count: "Count",
    shipping_record_columns: {
      id: 'ID',
      user_nickname: 'User Nickname',
      user_mobile: 'User Mobile',
      name: 'Recipient',
      mobile: 'Mobile',
      area: 'Area',
      address_detail: 'Address Detail',
      shipping_provider: 'Shipping Provider',
      shipping_no: 'Shipping No.',
      created_at: 'Created At',
      created_by: 'Created By',
      products: '商品信息',
      product_name: '商品名称',
      purchase_price: '采购价格',
      quantity: '数量'
    },
    update_success: "Update Success",
    update_fail: "Update Failed",
    confirm_cancel: 'Are you sure to cancel this shipping product?',
    cancel_success: 'Cancelled successfully',
    cancel_fail: 'Cancel failed',
    cancelled: "Cancelled",
  },
  es: {
    dashboard: "Tablero",
    layoutBuilder: "Constructor de maquetación",
    craft: "Elaborado",
    pages: "Paginas",
    profile: "Perfil",
    profileOverview: "Descripción general",
    projects: "Proyectos",
    campaigns: "Campañas",
    documents: "Documentos",
    connections: "Conexiones",
    wizards: "Magos",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Cuenta",
    accountOverview: "Descripción general",
    settings: "Ajustes",
    authentication: "Autenticación",
    basicFlow: "Flujo básico",
    signIn: "Registrarse",
    signUp: "Inscribirse",
    passwordReset: "Restablecimiento de contraseña",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Aplicaciones",
    chat: "Chat",
    privateChat: "Chat privado",
    groupChat: "Grupo de chat",
    drawerChat: "Chat del cajón",
    widgets: "Widgets",
    widgetsLists: "Liza",
    widgetsStatistics: "Estadísticas",
    widgetsCharts: "Gráficos",
    widgetsMixed: "Mezclada",
    widgetsTables: "Mesas",
    widgetsFeeds: "Alimenta",
    changelog: "Registro de cambios",
    docsAndComponents: "Documentos & Componentes",
    megaMenu: "Mega menú",
    exampleLink: "Enlace de ejemplo",
    modals: "Modales",
    general: "General",
    inviteFriends: "Invitar A Amigos",
    viewUsers: "Ver Usuarios",
    upgradePlan: "Plan De Actualización",
    shareAndEarn: "Compartir Y Ganar",
    forms: "Formas",
    newTarget: "Nuevo Objetivo",
    newCard: "Nueva Tarjeta",
    newAddress: "Nueva Direccion",
    createAPIKey: "Crea Clave De Api",
    twoFactorAuth: "Dos Factores",
    createApp: "Crear Aplicacion",
    createAccount: "Crear Una Cuenta",
    activity: "Actividad",
    documentation: "Documentación",
    components: "Componentes",
    resources: "Recursos",
    customers: "Clientes",
    gettingStarted: "Empezando",
    customersListing: "Listado De Clientes",
    customerDetails: "Detalles De Los Clientes",
    calendarApp: "Calendario",
    subscriptions: "Suscripciones",
    getStarted: "Empezando",
    subscriptionList: "Lista De Suscripción",
    addSubscription: "Añadir Suscripción",
    viewSubscription: "Suscripción",
  },
  de: {
    dashboard: "Instrumententafel",
    layoutBuilder: "Layout-Builder",
    craft: "Hergestellt",
    pages: "Seiten",
    profile: "Profil",
    profileOverview: "Überblick",
    projects: "Projekte",
    campaigns: "Kampagnen",
    documents: "Unterlagen",
    connections: "Anschlüsse",
    wizards: "Zauberer",
    horizontal: "Horizontal",
    vertical: "Vertikal",
    account: "Konto",
    accountOverview: "Überblick",
    settings: "Die Einstellungen",
    authentication: "Authentifizierung",
    basicFlow: "Grundfluss",
    signIn: "Einloggen",
    signUp: "Anmelden",
    passwordReset: "Passwort zurücksetzen",
    error404: "Fehler 404",
    error500: "Fehler 500",
    apps: "Apps",
    chat: "Plaudern",
    privateChat: "Private Chat",
    groupChat: "Privater Chat",
    drawerChat: "Gruppenchat Schubladen-Chat",
    widgets: "Widgets",
    widgetsLists: "Listen",
    widgetsStatistics: "Statistiken",
    widgetsCharts: "Diagramme",
    widgetsMixed: "Gemischt",
    widgetsTables: "Tabellen",
    widgetsFeeds: "Einspeisungen",
    changelog: "Änderungsprotokoll",
    docsAndComponents: "Dokumente & Komponenten",
    megaMenu: "Mega-Menü",
    exampleLink: "Beispiellink",
    modals: "Modale",
    general: "Allgemeines",
    inviteFriends: "Freunde Einladen",
    viewUsers: "Benutzer Anzeigen.",
    upgradePlan: "Upgrade-Plan",
    shareAndEarn: "Teilen & Verdienen",
    forms: "Formen",
    newTarget: "Neues Ziel",
    newCard: "Neue Karte",
    newAddress: "Neue Adresse",
    createAPIKey: "Api-Key Erstellen",
    twoFactorAuth: "Zwei Faktor Auth.",
    createApp: "App Erstellen",
    createAccount: "Benutzerkonto Erstellen",
    activity: "Aktivität",
    documentation: "Dokumentation",
    components: "Bauteile",
    resources: "Ressourcen",
    customers: "Kunden",
    gettingStarted: "Einstieg",
    customersListing: "Kundenauflistung",
    customerDetails: "Kundenangaben",
    calendarApp: "Kalender",
    subscriptions: "Abonnements",
    getStarted: "Einstieg",
    subscriptionList: "Abonnementliste",
    addSubscription: "Subskription Hinzufügen.",
    viewSubscription: "Abonnement Anzeigen.",
  },
  ja: {
    dashboard: "ダッシュボード",
    layoutBuilder: "レイアウトビルダー",
    craft: "作成された",
    pages: "ページ",
    profile: "プロフィール",
    profileOverview: "概要",
    projects: "プロジェクト",
    campaigns: "キャンペーン",
    documents: "書類",
    connections: "接続",
    wizards: "ウィザード",
    horizontal: "横",
    vertical: "垂直",
    account: "アカウント",
    accountOverview: "概要",
    settings: "設定",
    authentication: "認証",
    basicFlow: "基本的な流れ",
    signIn: "サイン",
    signUp: "インアップ",
    passwordReset: "パスワードのリセット",
    error404: "エラー404",
    error500: "エラー 500",
    apps: "アプリ",
    chat: "チャット",
    privateChat: "プライベートチャット",
    groupChat: "グループチャット",
    drawerChat: "ドロワーチャット",
    widgets: "ウィジェット",
    widgetsLists: "リスト",
    widgetsStatistics: "統計",
    widgetsCharts: "チャート",
    widgetsMixed: "混合",
    widgetsTables: "テーブル",
    widgetsFeeds: "フィード",
    changelog: "変更ログ",
    docsAndComponents: "ドキュメントとコンポーネント",
    megaMenu: "メガメニュー",
    exampleLink: "リンク例",
    modals: "モーダルズ",
    general: "一般",
    inviteFriends: "友達を招待",
    viewUsers: "ユーザーを表示します",
    upgradePlan: "アップグレードプラン",
    shareAndEarn: "シェア＆稼働",
    forms: "フォーム",
    newTarget: "新しいターゲット",
    newCard: "新しいカード",
    newAddress: "新しいアドス",
    createAPIKey: "Apiキーを作成します",
    twoFactorAuth: "2つの要因Auth",
    createApp: "アプリを作成します",
    createAccount: "アカウントを作する",
    activity: "アクティビティ",
    documentation: "ドキュメンテーション",
    components: "コンポーネント",
    resources: "資力",
    customers: "お客様のお客様",
    gettingStarted: "入門",
    customersListing: "顧客のリスト",
    customerDetails: "お客様の詳細",
    calendarApp: "カレンダー",
    subscriptions: "購読",
    getStarted: "入門",
    subscriptionList: "サブスクリプションリスト",
    addSubscription: "サブスクリプションを追加します",
    viewSubscription: "購読を見る",
  },
  fr: {
    dashboard: "Générateur de mise",
    layoutBuilder: "En page",
    craft: "Fabriqué",
    pages: "Pages",
    profile: "Profil",
    profileOverview: "Aperçu",
    projects: "Projets",
    campaigns: "Campagnes",
    documents: "Documents",
    connections: "Connexions",
    wizards: "Sorciers",
    horizontal: "Horizontal",
    vertical: "Verticale",
    account: "Compte",
    accountOverview: "Aperçu",
    settings: "Paramètres",
    authentication: "Authentification",
    basicFlow: "Flux de base",
    signIn: "SS'identifier",
    signUp: "Inscrivez-vous",
    passwordReset: "Réinitialisation du mot de passe",
    error404: "Erreur 404",
    error500: "Erreur 500",
    apps: "Applications",
    chat: "Discuter",
    privateChat: "Discussion privée",
    groupChat: "Discussion de groupe",
    drawerChat: "Chat de tiroir",
    widgets: "Widgets",
    widgetsLists: "Listes",
    widgetsStatistics: "Statistiques",
    widgetsCharts: "Graphiques",
    widgetsMixed: "Mixte",
    widgetsTables: "Les tables",
    widgetsFeeds: "Flux",
    changelog: "Journal des modifications",
    docsAndComponents: "Documents & composants",
    megaMenu: "Méga Menu",
    exampleLink: "Exemple de lien",
    modals: "Modals",
    general: "Général",
    inviteFriends: "Inviter Des Amis",
    viewUsers: "Voir Les Utilisateurs",
    upgradePlan: "Plan De Mise À Niveau",
    shareAndEarn: "Partager Et Gagner",
    forms: "Formes",
    newTarget: "Nouvelle Cible",
    newCard: "Nouvelle Carte",
    newAddress: "Nouvelle Adresse",
    createAPIKey: "Créer Une Touche Api",
    twoFactorAuth: "Deux Facteurs D'Authentification",
    createApp: "Créer Une Application",
    createAccount: "Créer Un Compte",
    activity: "Activité",
    documentation: "Documentation",
    components: "Composants",
    resources: "Ressources",
    customers: "Les Clients",
    gettingStarted: "Commencer",
    customersListing: "Liste Des Clients",
    customerDetails: "Détails Des Clients",
    calendarApp: "Calendrier",
    subscriptions: "Abonnements",
    getStarted: "Commencer",
    subscriptionList: "Liste D'Abonnement",
    addSubscription: "Ajouter Un Abonnement",
    viewSubscription: "Voir L'Abonnement",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "cn",
  globalInjection: true,
  messages,
});

export default i18n;
